.page {
  display: flex;
  flex-direction: column;
}
.form-section {
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  text-align: right;
  height: 100%;
  .webp & {
    background-image: url(../images/webp/bg-body.webp);
  }
  .no-webp & {
    background-image: url(../images/bg-body.jpg);
  }
  @media (min-width: 960px) {
    
  }
}
.form-holder {
  margin: auto;
  background: #fff;
  padding: 4.2rem 1rem;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  max-width: 65rem;
  @media (min-width: $query_medium) {
  }
}
.form-frame {
  margin: 0 auto;
  width: 100%;
  max-width: 45rem;
}
.form-row {
  text-align: center;
  @media (min-width: 440px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;  
  }
}
.input-row-add {
  @media (min-width: 440px) {
    margin: 0;
  }
}
.input-row {
  width: 23rem;
  margin: 0 auto 4rem;
  display:block;
  
}
.pass-row {
  .text-input {
    letter-spacing: 0.5rem;
    padding-right: 2.5rem;
  }
  .change-icon-visibility,
  .icon-svg {
    width: 2.2rem;
    height: 2.2rem;
    opacity: 0.6;
    position: absolute;
    right: 0;
    bottom: 1rem;
  }
  .change-icon-visibility {
    opacity: 0;
    margin: 0;
    z-index: 2;
    cursor: pointer;
  }
}
.button-row {
  display: inline-block;
  position: relative;
  &.button-margin-fix {
    margin-bottom: 4rem;
  }
  .loader {
    position: absolute;
    right: -5rem;
    top: 0;
  }
}
.loader {
  display: none;
}
.result-box {
  margin: 5.5rem 0 0;
  display:none;
  > strong {
    display: block;
    margin: 0 0 2.8rem;
  }
}
.result-text {
  background: #f2f2f2;
  border-radius: 0.8rem;
  position: relative;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2rem;
  padding: 3rem 2rem;
  box-sizing: border-box;
  span {
    text-transform: uppercase;
    color: #1d1d1f;
    opacity: 0.6;
  }
  .ico-svg {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 0;
    left: 50%;
    margin: -2rem 0 0 -2rem;
  }
}
.change-icon-visibility {
  
  &:checked~.icon-show {
    display:block;
  }
  &:checked~.icon-hide {
    display:none;
  }
}
.icon-show {
  display: none;
}