/* variables */
.clearfix { display: block; clear: both; content: ''; }

.transitions, .btn-form, .btn { transition: all .4s ease; }

.alignCenter { transform: translate(-50%, -50%); }

.pseudo-element { content: ''; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.flex-between, .select-holder, #header, #header .logo-wrapper, #header .logo, #header .logo-f24 { display: flex; justify-content: space-between; align-items: center; }

.wrapper { width: 100%; padding: 0 1.2rem; margin: 0 auto; box-sizing: border-box; max-width: 1100px; }

.vAlignMiddle { display: inline-block; vertical-align: middle; }

.btn-form { display: inline-block; width: 100%; vertical-align: top; padding: 1rem 1.2rem; box-sizing: border-box; margin: 0 auto; font-size: 1.8rem; font-weight: bold; border: none; outline: none; line-height: 2.4rem; color: #000; background: #D6BB82; outline: none !important; text-decoration: none; text-align: center; border-radius: 5px; }

.btn-form span, .btn-form .icon-arrow { display: inline-block; vertical-align: middle; }

.btn-form .icon-arrow { margin: 0 0 0 5px; color: color_text; stroke: currentColor; }

.btn-form:hover { background: #e9dabc; }

.btn-form:active { background: #f5efe2; }

.btn-form:disabled { cursor: default; opacity: 0.7; pointer-events: none; }

.btn-form > * { display: inline-block; vertical-align: middle; }

.btn-form .arrow { width: 9px; height: auto; margin: 0 0 0 10px; }

.icon-svg { fill: currentColor; }

.icon-arrow { width: 28px; height: 12px; }

/*mixins*/
/* loader
-----------------------------------------------------------------------------*/
.loader { margin: 1.6rem; font-size: 0.45rem; width: 1em; height: 1em; border-radius: 50%; position: absolute; text-indent: -9999em; -webkit-animation: load5 1.1s infinite ease; animation: load5 1.1s infinite ease; -webkit-transform: translateZ(0); -ms-transform: translateZ(0); transform: translateZ(0); }

@-webkit-keyframes load5 { 0%, 100% { box-shadow: 0em -2.6em 0em 0em #383839, 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.5), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.7); }
  12.5% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.7), 1.8em -1.8em 0 0em #383839, 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.5); }
  25% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.5), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.7), 2.5em 0em 0 0em #383839, 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  37.5% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.5), 2.5em 0em 0 0em rgba(56, 56, 57, 0.7), 1.75em 1.75em 0 0em #383839, 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  50% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.5), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.7), 0em 2.5em 0 0em #383839, -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  62.5% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.5), 0em 2.5em 0 0em rgba(56, 56, 57, 0.7), -1.8em 1.8em 0 0em #383839, -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  75% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.5), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.7), -2.6em 0em 0 0em #383839, -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  87.5% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.5), -2.6em 0em 0 0em rgba(56, 56, 57, 0.7), -1.8em -1.8em 0 0em #383839; } }

@keyframes load5 { 0%, 100% { box-shadow: 0em -2.6em 0em 0em #383839, 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.5), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.7); }
  12.5% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.7), 1.8em -1.8em 0 0em #383839, 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.5); }
  25% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.5), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.7), 2.5em 0em 0 0em #383839, 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  37.5% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.5), 2.5em 0em 0 0em rgba(56, 56, 57, 0.7), 1.75em 1.75em 0 0em #383839, 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  50% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.5), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.7), 0em 2.5em 0 0em #383839, -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.2), -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  62.5% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.5), 0em 2.5em 0 0em rgba(56, 56, 57, 0.7), -1.8em 1.8em 0 0em #383839, -2.6em 0em 0 0em rgba(56, 56, 57, 0.2), -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  75% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.5), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.7), -2.6em 0em 0 0em #383839, -1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2); }
  87.5% { box-shadow: 0em -2.6em 0em 0em rgba(56, 56, 57, 0.2), 1.8em -1.8em 0 0em rgba(56, 56, 57, 0.2), 2.5em 0em 0 0em rgba(56, 56, 57, 0.2), 1.75em 1.75em 0 0em rgba(56, 56, 57, 0.2), 0em 2.5em 0 0em rgba(56, 56, 57, 0.2), -1.8em 1.8em 0 0em rgba(56, 56, 57, 0.5), -2.6em 0em 0 0em rgba(56, 56, 57, 0.7), -1.8em -1.8em 0 0em #383839; } }

body { -webkit-text-size-adjust: 100%; }

input[type=submit], input[type=text], input[type=password], input[type=file], textarea { -webkit-appearance: none; }

input[type="image"] { padding: 0; border: none; }

img { border: none; }

form, fieldset { margin: 0; padding: 0; border-style: none; }

input, textarea, select { vertical-align: middle; color: #000; }

label { vertical-align: middle; }

input:focus { outline: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary { display: block; }

figure { margin: 1em 40px; }

audio { display: inline-block; vertical-align: baseline; }

audio:not([controls]) { display: none; height: 0; }

canvas { display: inline-block; vertical-align: baseline; }

progress { display: inline-block; vertical-align: baseline; }

video { display: inline-block; vertical-align: baseline; }

[hidden] { display: none; }

template { display: none; }

abbr[title] { border-bottom: 1px dotted; }

b { font-weight: bold; }

strong { font-weight: bold; }

dfn { font-style: italic; }

img { border: 0; border: none; max-width: 100%; }

hr { box-sizing: content-box; height: 0; }

small { font-size: 80%; }

sub { bottom: -0.25em; }

sup { top: -0.5em; }

pre { overflow: auto; font-size: 1em; }

code { font-size: 1em; }

kbd { font-size: 1em; }

samp { font-size: 1em; }

button { overflow: visible; text-transform: none; cursor: pointer; }

input { line-height: normal; outline: none; }

optgroup { font-weight: bold; }

select { text-transform: none; }

select:focus { box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.4); }

textarea { overflow: auto; }

textarea:focus { box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.4); }

input[type="reset"] { cursor: pointer; }

input[type="submit"] { cursor: pointer; }

button[disabled] { cursor: default; }

button::-moz-focus-inner { border: 0; padding: 0; }

input::-moz-focus-inner { border: 0; padding: 0; }

input[type="checkbox"] { box-sizing: border-box; padding: 0; }

input[type="radio"] { box-sizing: border-box; padding: 0; }

input[type="number"]::-webkit-inner-spin-button { height: auto; }

input[type="number"]::-webkit-outer-spin-button { height: auto; }

input[type="search"] { box-sizing: content-box; }

fieldset { border: 1px solid; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }

legend { border: 0; padding: 0; }

table { border-collapse: collapse; border-spacing: 0; }

td { padding: 0; }

th { padding: 0; }

ul { margin: 0; list-style-type: none; padding: 0; }

html { font-size: 62.5%; height: 100%; font-size: 10px; }

html input[type="button"] { cursor: pointer; }

html input[disabled] { cursor: default; }

body { font-family: "Noto Sans", Verdana, Arial, sans-serif; font-size: 1.8rem; line-height: 1.4; margin: 0; color: #000; min-width: 320px; box-sizing: border-box; color: #000; height: 100%; }

a { background-color: transparent; color: color_text; text-decoration: underline; }

a:active { outline: 0; }

a:hover { outline: 0; text-decoration: none; }

h1, h2, h3 { font-size: 2.4rem; margin: 0 0 3rem; font-weight: bold; color: #000; line-height: 1.25; text-transform: uppercase; }

h2 { text-align: center; font-size: 2rem; margin-bottom: 4.8rem; font-weight: normal; text-transform: none; }

h3 { font-size: 1.8rem; }

p { margin: 0 0 1.8rem; }

button { outline: 0 none; }

.mob-visible { display: none; }

@media only screen and (max-width: 560px), only screen and (max-device-width: 560px) { .mob-visible { display: block; } }

.mob-hide { display: none; }

@media (min-width: 720px) { .mob-hide { display: block; } }

.input-holder { position: relative; }

.text-input { box-sizing: border-box; color: #000; width: 100%; font-size: 2.4rem; line-height: 2.8rem; padding: 0.6rem 0; text-align: left; border: none; border-bottom: 1px solid #000; }

.text-input.error { border-color: #CA5455 !important; }

.text-input:active, .text-input:focus { border-color: #D6BB82; }

.text-input ::-webkit-input-placeholder { color: #030305; font-weight: light; }

.text-input ::-moz-placeholder { color: #030305; font-weight: light; }

.text-input :-moz-placeholder { color: #030305; font-weight: light; }

.text-input :-ms-input-placeholder { color: #030305; font-weight: light; }

.radio-row { overflow: hidden; }

span.error { display: block; font-size: 1.2rem; line-height: 1.8rem; padding: 5px 0 0; color: #CA5455; text-align: left; position: absolute; left: 0; bottom: -1.8rem; }

.input-row { position: relative; margin: 0 0 1rem; }

label { display: block; color: #aaa; font-weight: bold; text-transform: uppercase; font-size: 1.8rem; text-align: left; }

.btn { display: inline-block; vertical-align: top; padding: 0.8rem 2rem; box-sizing: border-box; border-radius: 2px; font-size: 1.8rem; border: none; outline: none; line-height: 2.2rem; color: #fff; background: #383839; text-transform: uppercase; text-decoration: none; text-align: center; }

.btn.btn-enter { width: 18.5rem; }

.btn .icon-svg { color: #D6BB82; width: 2.25rem; height: 1.2rem; margin: 0 0 0 0.5rem; }

.btn:hover { background: #121212; }

.btn:active { background: black; }

.btn:disabled { cursor: default; opacity: 0.7; pointer-events: none; }

select { padding: 0.3rem 2.2rem 0.3rem 0.3rem; font-size: 2.4rem; border: none; border-bottom: 1px solid #030305 !important; -webkit-appearance: none; -moz-appearance: none; appearance: none; background: url(../images/ico-arrow-sel.svg) right center no-repeat; background-size: 1.3rem 0.6rem; cursor: pointer; }

select.error { border-color: #CA5455 !important; }

select:active, select:focus { box-shadow: none; border: none; outline: none; }

select:after { display: block; border: 1px solid #030305; content: ''; }

/* Header
-----------------------------------------------------------------------------*/
#header { width: 100%; box-sizing: border-box; margin: 0 auto; padding: 1rem; max-width: 1190px; z-index: 10; height: 4.4rem; display: inline-flex; }

@media (min-width: 780px) { #header { height: 6.4rem; } }

#header .logo-wrapper { min-width: 100px; }

#header .icon-logo { color: #000; height: 1.6rem; width: 19rem; }

@media (min-width: 960px) { #header .icon-logo { width: 37rem; height: 3.5rem; } }

#header .logo-f24 { font-size: 1rem; color: #000; }

@media (min-width: 960px) { #header .logo-f24 { font-size: 1.3rem; } }

#header .ico-check { width: 1.05rem; height: 1.05rem; margin: 0 1rem 0 1.5rem; }

@media (min-width: 960px) { #header .ico-check { margin: 0 1rem 0 2.5rem; width: 2.1rem; height: 2.1rem; } }

/* content
-----------------------------------------------------------------------------*/
.page { display: flex; flex-direction: column; }

.form-section { display: inline-flex; background-repeat: no-repeat; background-position: center top; background-size: cover; text-align: right; height: 100%; }

.webp .form-section { background-image: url(../images/webp/bg-body.webp); }

.no-webp .form-section { background-image: url(../images/bg-body.jpg); }

.form-holder { margin: auto; background: #fff; padding: 4.2rem 1rem; box-sizing: border-box; text-align: center; width: 100%; max-width: 65rem; }

.form-frame { margin: 0 auto; width: 100%; max-width: 45rem; }

.form-row { text-align: center; }

@media (min-width: 440px) { .form-row { display: flex; justify-content: space-between; align-items: flex-end; } }

@media (min-width: 440px) { .input-row-add { margin: 0; } }

.input-row { width: 23rem; margin: 0 auto 4rem; display: block; }

.pass-row .text-input { letter-spacing: 0.5rem; padding-right: 2.5rem; }

.pass-row .change-icon-visibility, .pass-row .icon-svg { width: 2.2rem; height: 2.2rem; opacity: 0.6; position: absolute; right: 0; bottom: 1rem; }

.pass-row .change-icon-visibility { opacity: 0; margin: 0; z-index: 2; cursor: pointer; }

.button-row { display: inline-block; position: relative; }

.button-row.button-margin-fix { margin-bottom: 4rem; }

.button-row .loader { position: absolute; right: -5rem; top: 0; }

.loader { display: none; }

.result-box { margin: 5.5rem 0 0; display: none; }

.result-box > strong { display: block; margin: 0 0 2.8rem; }

.result-text { background: #f2f2f2; border-radius: 0.8rem; position: relative; text-transform: uppercase; font-size: 1.8rem; line-height: 2rem; padding: 3rem 2rem; box-sizing: border-box; }

.result-text span { text-transform: uppercase; color: #1d1d1f; opacity: 0.6; }

.result-text .ico-svg { position: absolute; width: 4rem; height: 4rem; top: 0; left: 50%; margin: -2rem 0 0 -2rem; }

.change-icon-visibility:checked ~ .icon-show { display: block; }

.change-icon-visibility:checked ~ .icon-hide { display: none; }

.icon-show { display: none; }
