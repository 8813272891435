#header {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1rem;
  max-width: 1190px;
  @extend .flex-between;
  z-index: 10;
  height: 4.4rem;
  display: inline-flex;
  @media (min-width: 780px) {
    height: 6.4rem;
  }
  .logo-wrapper {
    @extend .flex-between;
    min-width: 100px;
  }
  .logo {
    @extend .flex-between;
  }
  .icon-logo {
    color: $color_text;
    height: 1.6rem;
    width: 19rem;
    @media (min-width: 960px) {
      width: 37rem;
      height: 3.5rem;
    }
  }
  .logo-f24 {
    @extend .flex-between;
    font-size: 1rem;
    color: $color_text;
    @media (min-width: 960px) {
      font-size: 1.3rem;
    }
  }
  .ico-check {
    width: 1.05rem;
    height: 1.05rem;
    margin: 0 1rem 0 1.5rem;
    @media (min-width: 960px) {
      margin: 0 1rem 0 2.5rem;
      width: 2.1rem;
      height: 2.1rem;
    }
  }
}
