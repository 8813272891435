html {
  font-size: 62.5%;
  height: 100%;
  input[type="button"] {
    cursor: pointer;
  }
  input[disabled] {
    cursor: default;
  }
  font-size: 10px;
  @media (min-width: $query_medium) {
   
  }
}
body {
  font-family:$font_regular;
  font-size: 1.8rem;
  line-height: 1.4;
  margin: 0;
  color: $color_text;
  min-width: 320px;
  box-sizing: border-box;
  color:$color_text;
  height: 100%;
  @media (min-width: 980px) {
    
  }
}
a {
  background-color: transparent;
  color: color_text;
  text-decoration: underline;
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
    text-decoration: none;
  }
}
h1, h2, h3 {
  font-size: 2.4rem;
  margin:0 0 3rem;
  font-weight: bold;
  color: $color_text;
  line-height:1.25;
  text-transform: uppercase;
  @media (min-width: 720px) {
    
  }
}
h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 4.8rem;
  font-weight: normal;
  text-transform: none;
}
h3 {
  font-size: 1.8rem;
}
p {
  margin: 0 0 1.8rem;
}
button {
  outline: 0 none;
}
.mob-visible {
  display: none;
  @media only screen and(max-width: 560px),only screen and(max-device-width: 560px) {
    display: block;
  }
}
.mob-hide {
  display: none;
  @media (min-width: 720px) {
    display: block;
  }
}
.input-holder {
  position: relative;
}
.text-input {
  box-sizing: border-box;
  color: $color_text;
  width: 100%;
  font-size: 2.4rem;
  line-height: 2.8rem;
  padding:0.6rem 0;
  text-align: left;
  border: none;
  border-bottom: 1px solid $color_text;
  &.error {
    border-color:#CA5455 !important;
  }
  &:active, &:focus {
    border-color: $color_template;
  }
  ::-webkit-input-placeholder {
    color:$color_placeholder;
    font-weight: light;
  }
  ::-moz-placeholder {
    color:$color_placeholder;
    font-weight: light;
  }
  :-moz-placeholder {
    color:$color_placeholder;
    font-weight: light;
  }
  :-ms-input-placeholder {
    color:$color_placeholder;
    font-weight: light;
  }
}
.radio-row {
  overflow: hidden;
}

span.error {
  display: block;
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding:5px 0 0;
  color: #CA5455;
  text-align: left;
  position: absolute;
  left: 0;
  bottom: -1.8rem;
}
.input-row {
  position: relative;
  margin:0 0 1rem;
}
label {
  display:block;
  color: #aaa;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.8rem;
  text-align: left;
}
.btn {
  display: inline-block;
  vertical-align: top;
  padding: 0.8rem 2rem;
  box-sizing:border-box;
  @extend .transitions;
  border-radius: 2px;
  font-size: 1.8rem;
  border: none;
  outline: none;
  line-height: 2.2rem;
  color: #fff;
  background: $btn_bg;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  &.btn-enter {
    width: 18.5rem;
  }
  @media (min-width: 980px) {
    
  }

  .icon-svg {
    color: $color_template;
    width: 2.25rem;
    height: 1.2rem;
    margin: 0 0 0 0.5rem;
  }
  &:hover {
    background: darken($btn_bg, 15%);
  }
  &:active {
    background: darken($btn_bg, 25%);
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  }
}
select {
  padding: 0.3rem 2.2rem 0.3rem 0.3rem;
  font-size: 2.4rem;
  border: none;
  border-bottom: 1px solid $color_placeholder !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../images/ico-arrow-sel.svg) right center no-repeat;
  background-size: 1.3rem 0.6rem;
  cursor: pointer;
  &.error {
    border-color:#CA5455 !important;
  }
  &:active, &:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }
  &:after {
    display:block;
    border: 1px solid $color_placeholder;
    content: '';
  }
}
.select-holder {
  @extend .flex-between;
}