//@extend-elements
.clearfix {
  display: block;
  clear: both;
  content: '';
}
.transitions {
  transition: all .4s ease;
}
.alignCenter {
  transform: translate(-50%, -50%);
}
.pseudo-element {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper {
  width: 100%;
  padding:0 1.2rem;
  margin:0 auto;
  box-sizing: border-box;
  max-width: 1100px;
}
.vAlignMiddle {
  display: inline-block;
  vertical-align: middle;
}
.btn-form {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 1rem 1.2rem;
  box-sizing:border-box;
  @extend .transitions;
  margin: 0 auto;
  font-size: 1.8rem;
  font-weight: bold;
  border: none;
  outline: none;
  line-height: 2.4rem;
  color: $color_text;
  background: $color_template;
  outline: none !important;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  span, .icon-arrow {
    display: inline-block;
    vertical-align: middle;
  }
  .icon-arrow {
    margin:0 0 0 5px;
    color: color_text;
    stroke: currentColor;
  }
  &:hover {
    background: lighten($color_template, 15%);
  }
  &:active {
    background: lighten($color_template, 25%);
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  }
  >* {
    display: inline-block;
    vertical-align: middle;
  }
  .arrow {
    width: 9px;
    height: auto;
    margin:0 0 0 10px;
  }
}

.icon-svg {
  fill: currentColor;
}
.icon-arrow {
  width: 28px;
  height: 12px;
}