/* variables */
//colors
$color_text: #000;
$color_input_placeholder: #ADADAD;
$color_template: #D6BB82;
$btn_bg: #383839;
$color_placeholder: #030305;


$query_medium: 780px;




//fonts
$font_regular: 'Noto Sans', Verdana, Arial, sans-serif;